<template>
  <v-container id="login" class="fill-height" tag="section">
    <v-row justify="center">
      <v-col cols="12" md="9">
        <div class="display-4 white--text text-center text-md-left">
          Welcome to
          <span class="font-weight-bold">Odro Producer</span>
        </div>
        <div class="display-1 grey--text text-center text-md-left">Sign in with your Odro login to get started</div>
        <v-divider dark class="my-4" />
        <div class="hidden-sm-and-down display-1 grey--text">
          It’s time to start creating great video content. Be brave, be bold, and don’t worry, we’ve left you loads of
          hints and tips to make the whole process a breeze.
        </div>
        <div class="hidden-sm-and-down body-1 mt-2 grey--text">After all, we know how to make a great video.</div>
      </v-col>
      <v-col cols="12" md="3" class="text-center">
        <v-row justify="center" align="center" class="fill-height">
          <v-card color="transparent">
            <v-card-text style="border: 1px solid grey">
              <v-btn color="primary" x-large @click="login">Sign In Now</v-btn>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  layout: 'public',
  fetch({ store, req, redirect }) {
    // If we land on a whitelabel domain, redirect them to the standard baseUrl
    // as Whitelabel clients have no OAuth setup
    if (req?.hostname && !store.$config.baseUrl.includes(req.hostname)) {
      return redirect(store.$config.baseUrl);
    }
  },
  methods: {
    login() {
      this.$auth.loginWith('vision');
    },
  },
};
</script>
